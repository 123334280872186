<template>
  <div class="departure_monitor__delay" v-html="delay"></div>
</template>

<script>
export default {
  name: 'Delay',
  props: {
    cancellation: {
      type: String
    },
    entry: {
      type: Object
    }
  },
  data: function() {
    return {
    }
  },
  created: function() {
  },
  computed: {
    delay: function() {
      if (this.entry.cancellation == true) {
        return this.cancellation;
      }

      if (this.entry.delay > 0) {
        var minutes = (this.entry.delay == 1) ? ' Minute' : ' Minuten';
        return '+' + this.entry.delay + minutes
      }

      return '&nbsp;';
    }
  },
  methods: {
  }
}
</script>
