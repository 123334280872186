export default {
  "cancellation": "Fällt aus!",
  "delay": {
    "one": "+1 Minute",
    "other": "+%{count} Minuten"
  },
  "form": {
    "select_prompt": "Abfahrtsbahnhof wählen ...",
    "station": "Abfahrtsbahnhof",
    "submit": "Anzeigen"
  },
  "headline": "Abfahrtsmonitor",
  "no_entries": "Es liegen aktuell keine Abfahrtsinformation vor.",
  "table": {
    "headline": {
      "departure": "Abfahrt",
      "departure_en": "Departure",
      "destination": "Richtung / Zug",
      "destination_en": "Direction / Train",
      "forecast": "Prognose",
      "forecast_en": "Forecast",
      "info": "Hinweis",
      "info_en": "Information",
      "traction": "Anzahl der Fahrzeuge",
      "traction_en": "Number of vehicles",
      "platform": "Bahnsteig / Gleis",
      "platform_en": "Platform"
    }
  }
}
