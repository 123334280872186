<template>
  <fid-slideshow
    :slides-url="slidesUrl"
    :departures-url="departuresUrl"
    :station="station"
    :source="source"
  />
</template>

<script>

import FidSlideshow from '@/components/fid_slideshow'

export default {
  name: 'Home',

  components: {
    FidSlideshow
  },

  computed: {
    tenant () {
      switch (document.location.host) {
        case 'abfahrtsmonitor.sbahn-hannover.de':
          return 'sbahn_hannover'
        case 'abfahrtsmonitor.nordwestbahn.de':
          return 'nwb'
        case 'abfahrtsmonitor.mittelrheinbahn.de':
          return 'mrb'
        case 'abfahrtsmonitor.tickets-go-ahead.bayern':
          return 'goahead'
        case 'abfahrtsmonitor.transdev-vrr-tickets.de':
          return 'vrr'
        case 'abfahrtsmonitor.agilis-tickets-info.de':
          return 'agilis'
        default:
          return 'nwb'
      }
    },

    source () {
      switch (this.$route.query.source) {
        case 'defas':
          return 'defas'
        case 'efa':
          return 'efa'
        default:
          return 'rbl'
      }
    },

    station () {
      return this.$route.query.station
        ? this.$route.query.station
        : this.$route.query.haltestellenId
    },

    baseUrl () {
      switch (document.location.host) {
        case 'localhost:8080':
          return 'http://127.0.0.1:3000'
        default:
          return 'https://api.transdev.de'
      }
    },

    slidesUrl () {
      return `${this.baseUrl}/base/v1/${this.tenant}/departure_monitor_slides.json?station=${this.station}&source=${this.source}`
    },

    departuresUrl () {
      return `${this.baseUrl}/base/v1/departures.json`
    },
  }
}
</script>
