<template>
  <div class="slideshow__slide-countdown">
    <div ref="progress" class="slideshow__slide-progress"></div>
    <div class="slideshow__slide-progress-value">{{ timeleft }}</div>
  </div>
</template>

<script>
import ProgressBar from 'progressbar.js'

export default {
  name: 'Countdown',
  props: {
    delay: {
      type: Number
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      progress_circle: null,
      timeleft: (this.delay / 1000),
      counter: 1
    }
  },
  watch: {
    counter: function() {
      var retval = (this.counter / this.delay) * 1000;
      this.progress_circle.animate(retval);
    },
    active: function() {
      if(this.active)
      {
        this.progress_circle.set(0);
        this.counter = 1;
        this.timeleft = (this.delay / 1000);
        this.startTimer();
      }
    }
  },
  mounted: function() {
    this.progress_circle = new ProgressBar.Circle(this.$refs.progress, {
      strokeWidth: 6,
      duration: 1400,
      color: '#fff',
      // trailColor: '#fff',
      trailWidth: 1,
      svgStyle: null,
      easing: 'easeInOut'
    });
  },
  computed: {
    progress: function() {
      return '40'
    }
  },
  methods: {
    startTimer: function() {
      var that = this;
      var Timer = setInterval(function(){
        that.timeleft -= 1;
        that.counter += 1;

        if(that.timeleft <= 0)
          clearInterval(Timer);
      },1000);
    }
  }
}
</script>
