import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: { name: 'Index' }
  // },
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/de/abfahrtsmonitor.fid',
    name: 'IndexOld',
    component: Index
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
