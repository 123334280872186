<template>
  <div class="departure_monitor">
    <fid-clock />
    <p v-if="entries.length == 0" class="departure_monitor__no-entries">
      {{ translations.no_entries }}
    </p>

    <table v-else>
      <col width="170px" />
      <col width="170px" />
      <col width="500px" />
      <col v-if="properties.traction_available" width="400px" />
      <col v-if="properties.platform_available" width="220px" />
      <col />

      <thead>
        <tr>
          <th>
            <div class="departure_monitor__header">{{ translations.table.headline.departure }}</div>
            <div class="departure_monitor__header--en">{{ translations.table.headline.departure_en }}</div>
          </th>
          <th>
            <div class="departure_monitor__header">{{ translations.table.headline.forecast }}</div>
            <div class="departure_monitor__header--en">{{ translations.table.headline.forecast_en }}</div>
          </th>
          <th>
            <div class="departure_monitor__header">{{ translations.table.headline.destination }}</div>
            <div class="departure_monitor__header--en">{{ translations.table.headline.destination_en }}</div>
          </th>
          <th v-if="properties.traction_available">
            <div class="departure_monitor__header">{{ translations.table.headline.traction }}</div>
            <div class="departure_monitor__header--en">{{ translations.table.headline.traction_en }}</div>
          </th>
          <th v-if="properties.platform_available">
            <div class="departure_monitor__header">{{ translations.table.headline.platform }}</div>
            <div class="departure_monitor__header--en">{{ translations.table.headline.platform_en }}</div>
          </th>
          <th>
            <div class="departure_monitor__header">{{ translations.table.headline.info }}</div>
            <div class="departure_monitor__header--en">{{ translations.table.headline.info_en }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, index) in entries"
          :key="index"
          :class="status_class(entry)"
        >
          <td class="departure_monitor__departure">
            <div
              v-if="!entry.cancellation"
              class="departure_monitor__time"
            >{{ entry.departure_time }}</div>
            <fid-delay :entry="entry" :cancellation="translations.cancellation" />
          </td>

          <td class="departure_monitor__forecast">
            <div class="departure_monitor__time">{{ entry.forecast_time }}</div>
            <div class="departure_monitor__delay">&nbsp;</div>
          </td>

          <td class="departure_monitor__destination">
            <div class="departure_monitor__station">{{ entry.direction }}</div>
            <div class="departure_monitor__transport">{{ entry.transport }}</div>
          </td>

          <td
            v-if="properties.traction_available"
            class="departure_monitor__traction"
          >
            <ul v-if="!entry.cancellation" class="departure_monitor__traction-list">
              <fid-traction
                v-for="(number,index) in 3"
                :key="index"
                :traction="entry.traction[index]"
              />
            </ul>
          </td>

          <td
            v-if="properties.platform_available"
            class="departure_monitor__platform"
          >
            {{ entry.platform }}
          </td>

          <td class="departure_monitor__info">
            <p v-if="Array.isArray(entry.info)">
              {{ entry.info.join() }}
            </p>
            <p v-else>
              {{ entry.info }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Clock from './fid_clock.vue'
import Delay from './fid_delay.vue'
import Traction from './fid_traction.vue'

export default {
  name: 'DepartureMonitor',
  components: {
    'fid-clock': Clock,
    'fid-delay': Delay,
    'fid-traction': Traction
  },
  props: {
    translations: {
      type: Object
    },
    url: {
      type: String
    },
    station: {
      type: String
    },
    source: {
      type: String
    }
  },
  data: function() {
    return {
      interval: null,
      interval_delay: 60000,
      entries: [],
      version: null,
      properties: null
    }
  },
  created: function() {
    this.fetchEntries()
  },
  watch: {
    version: function(newVal, oldVal) {
      if (oldVal !== null && newVal !== 'error') {
        window.location.reload(true)
      }
    },
  },
  methods: {
    status_class: function(entry) {
      return 'departure_monitor__status--' + entry.status
    },
    fetchEntries: function() {
      var that = this;

      clearInterval(this.interval);

      var params = {
        source: this.source,
        station: this.station,
      }

      this.axios.get(this.url, {params: params}).then(function(response) {
        if (that.version !== 'error') {
          that.entries = response.data.departures;
        }
        that.version = response.data.version;
        that.properties = response.data.properties;

        that.interval = window.setInterval(function () {
          that.fetchEntries();
        }, that.interval_delay);
      }, () => {
        that.interval = window.setInterval(function () {
          that.fetchEntries();
        }, that.interval_delay);

        that.version = 'error';
        that.entries = [];
        // console.log('error', response);
      })
    }
  }
}
</script>
