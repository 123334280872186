<template>
  <div class="departure_monitor__clock">
    <span>{{ hour }}</span>
    <span style="width: 10px; text-align: center; display: inline-block;" v-html="delimiter"></span>
    <span>{{ minute }}</span>
  </div>
</template>

<script>
export default {
  name: 'Clock',
  data: function() {
    return {
      blink: true,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  created: function() {
    var that = this;

    that.updateClock();

    window.setInterval(function(){
      that.updateClock()
    }, 1000);
  },
  watch: {
    seconds: function() {
      // reload page every hour
      if(this.seconds == 0 && this.minutes == 0)
      {
        window.location.reload();
      }
    }
  },
  computed: {
    hour: function() {
      return ('0' + this.hours).slice(-2);
    },
    minute: function() {
      return ('0' + this.minutes).slice(-2);
    },
    delimiter: function() {
      return this.blink ? ' ' : ':';
    }
  },
  methods: {
    updateClock: function() {
      var now = new Date();

      this.hours = now.getHours();
      this.minutes = now.getMinutes();
      this.seconds = now.getSeconds();
      this.blink = !this.blink;
    }
  }
}
</script>
