<template>
  <div class="slideshow" :class="slideshowContainerClass">
    <div class="slideshow__container">
      <div class="slideshow__slide">
        <fid-departures-monitor
          :translations="translations"
          :station="station"
          :source="source"
          :url="departuresUrl"
        />
      </div>
      <div
        v-show="slide_index == index"
        class="slideshow__slide"
        v-for="(slide, index) in slides"
        :key="index"
      >
        <p class="slideshow__slide-text">{{ slide.text }}</p>
        <img v-if="slide.image" :src="slide.image" :alt="slide.text" class="slideshow__slide-image">
        <fid-countdown :id="index" :active="!show_departure_monitor && slide_index == index" :delay="slide.delay" />
      </div>
    </div>
  </div>
</template>

<script>
import translations from '@/data/translations'
import DepartureMonitor from './fid_departure_monitor.vue'
import Countdown from './fid_countdown.vue'

export default {
  name: 'Slideshow',
  components: {
    'fid-departures-monitor': DepartureMonitor,
    'fid-countdown': Countdown
  },
  props: {
    station: {
      type: String
    },
    source: {
      type: String
    },
    slidesUrl: {
      type: String
    },
    departuresUrl: {
      type: String
    }
  },
  data: function() {
    return {
      recheck_timeout: 60000,
      timeout_handle: null,
      show_departure_monitor: true,
      departures_monitor_delay: 15000,
      slide_index: 0,
      slides: [],
      loop_count: 0,
      translations
    }
  },
  mounted: function() {
    this.fetchSlides();
  },
  watch: {
    loop_count: function() {
      if(this.loop_count == 1)
      {
        this.stop();
      }
    }
  },
  computed: {
    current_slide: function() {
      return this.slides[this.slide_index];
    },
    slide_delay: function() {
      return (this.current_slide == undefined) ? this.departures_monitor_delay : this.current_slide.delay
    },
    slideshowContainerClass: function() {
      return (this.show_departure_monitor) ? '' : 'slideshow--active'
    }
  },
  methods: {
    start: function() {
      var that = this;

      if(that.slides.length > 0)
      {
        that.timeout_handle = setTimeout(function(){
          that.toggle();
        }, that.departures_monitor_delay)
      }
    },
    stop: function() {
      window.clearTimeout(this.timeout_handle);
      this.show_departure_monitor = true;
      this.slides = [];
      this.slide_index = 0;
      this.loop_count = 0;
      this.refresh();
    },
    refresh: function() {
      this.fetchSlides();
    },
    nextSlide: function() {
      var that = this;

      if(that.slide_index == that.slides.length-1)
      {
        that.slide_index = 0;
        that.loop_count += 1;

      }
      else
      {
        that.slide_index += 1;
      }
    },
    toggle: function() {
      var that = this;

      if (that.slides.length == 0) return;

      if(that.show_departure_monitor)
      {
        that.show_departure_monitor = false

        that.timeout_handle = setTimeout(function(){
          that.toggle();
        }, that.slide_delay)
      }
      else
      {
        that.show_departure_monitor = true

        that.timeout_handle = setTimeout(function(){
          that.nextSlide();
          that.toggle();
        }, that.departures_monitor_delay)
      }
    },
    fetchSlides: function() {
      this.slide_index = 0
      this.show_departure_monitor = true

      this.axios.get(this.slidesUrl).then(response => {
        this.slides = response.data

        if(this.slides.length == 0)
        {
          this.timeout_handle = setTimeout(() => {
            this.fetchSlides()
          }, this.recheck_timeout)
        }
        else
        {
          this.start()
        }
      }, () => {
        // error callback
        // console.log('error', response)

      })
    }
  }
}
</script>
